import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { duplicateProjectAPI } from '@zert-packages/plugins/ExplorerPlugin/API';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import getMultipleValue from '@zert-packages/utils/getMultipleValue';
import { placeElementNew } from '@zert-packages/actions/catalogActions';
import { useSelector } from 'react-redux';
import CatalogPlacement from '../../../shared/CatalogPlacement/CatalogPlacement';
import DuplicationDefaultDialog from '../components/DuplicationDefaultDialog';

const useStyles = makeStyles({
  root: {}
});

function Project({ unmountComponent, onFinish, element }) {
  const cl = useStyles();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(`${get(element, 'name', '')} - duplication`);
  const { catalog, loadingCatalog } = useSelector((state) => state);
  const [multipleValue, setMultipleValue] = useState(getMultipleValue(catalog));

  const onDuplicate = () => {
    setLoading(true);
    let idElement = -1;
    duplicateProjectAPI(element.versionId, name)
      .then((r) => {
        idElement = r.left?.versionId;
        placeElementNew(idElement, multipleValue).catch((e) => {
          showSnackbarError(snackbar, e.message);
        });
        setLoading(false);
        unmountComponent();
        onFinish(r);
      })
      .catch((e) => {
        showSnackbarError(snackbar, e.message);
        setLoading(false);
      });
  };

  const assignChangedValues = (data) => {
    setMultipleValue(data);
  };

  const onDuplicateDisabled = name.trim().length === 0;
  return (
    <DuplicationDefaultDialog
      unmountComponent={unmountComponent}
      onDuplicate={onDuplicate}
      onDuplicateDisabled={onDuplicateDisabled}
      loading={loading}
      customDialogContent={
        <div className={cl.root}>
          <TextField
            autoFocus={!loading}
            fullWidth
            label={<FormattedMessage id="DuplicationDialogs.Project.NameField" defaultMessage="Name" />}
            value={name}
            disabled={loading}
            onChange={(e) => setName(e.target.value)}
          />
          {catalog && (
            <>
              <div className="captionLabel">
                <FormattedMessage id="newpage.project.placement" defaultMessage="Placement in catalog" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <CatalogPlacement
                  assignChagedValues={assignChangedValues}
                  multipleValue={multipleValue}
                  catalog={catalog}
                  loading={loadingCatalog}
                />
              </div>
            </>
          )}
        </div>
      }
    />
  );
}

export default Project;
