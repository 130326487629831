import makeStyles from '@mui/styles/makeStyles';
import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MatrixInlineView from '@zert-packages/components//common/RiskAssesmentEdit/MatrixInlineView';
import { getStatusIcon } from '@zert-packages/components/common/RiskAssesmentEdit/helpers/getStatusIcon';
import { getRiskRowAsync } from '@zert-packages/actions/measureActions';
import { calculateRowOverview } from '@zert-packages/actions/overviewCoverage';
import { handleQuery } from '@zert-packages/actions/api';
import cn from 'classnames';
import { getOrigin } from '@zert-packages/actions/coreReducers';
import withTile from '../shared/withTile';
import { ElementContentOverviewTile, OverviewTile, TileIcon } from './AbstractTile';

const BACKGROUND_COLOR = '#dadada';

const useStyles = makeStyles({
  '@keyframes opacityAnimation': {
    '0%': {
      opacity: 0.6
    },
    '50%': {
      opacity: 0.85
    },
    '100%': {
      opacity: 0.6
    }
  },
  root: {
    height: 50,
    marginBottom: 5,
    boxSizing: 'border-box',
    padding: '8px 2px',
    animation: `$opacityAnimation 2s infinite`,
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 2fr 1fr'
  },
  bar: {
    background: BACKGROUND_COLOR,
    borderRadius: 3
  },
  riskRow: {},
  measureRow: {
    alignItems: 'flex-start',
    paddingLeft: 4
  },
  matrix: {
    width: '50px',
    height: '50px',
    borderRadius: 2,
    background: BACKGROUND_COLOR
  },
  measures: {
    height: '100%',
    alignItems: 'center'
  },
  measureStatus: {
    width: 20,
    height: 20,
    marginBottom: 5
  },
  measure: {
    width: 40,
    height: 20,
    marginBottom: 5
  }
});

const LoadingRiskRowTile = memo(
  forwardRef((props, ref) => {
    const cl = useStyles();
    return (
      <div ref={ref} className={cl.root}>
        <div className={cl.riskRow}>
          <div className={cl.matrix} />
        </div>
        <div className={cl.measures}>
          <div className={cn(cl.bar, cl.measureStatus)} />
          <div className={cn(cl.bar, cl.measure)} />
        </div>
        <div className={cl.measureRow}>
          <div className={cl.matrix} />
        </div>
      </div>
    );
  })
);

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
};

function RiskRowTile(props) {
  const [riskRow, setRiskRow] = useState(null);
  const [templateBundle, setTemplateBundle] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [canMount, setCanMount] = useState(false);
  const replacerRef = useRef();
  const [versionId, setVersionId] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observer.observe(replacerRef.current);
    return () => observer.disconnect();
  }, []);

  const observerCallback = ([entry]) => {
    if (entry.intersectionRatio > 0.1) {
      setCanMount(true);
    }
  };

  useEffect(() => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    getRiskRowAsync(props.element.id)
      .then((result) => {
        setRiskRow(result);
        setVersionId(result.status.versionId);
        return handleQuery(`/measure/getAllTemplates/${locale}/${result.status.versionId}`);
      })
      .then((result) => {
        setTemplateBundle(result);
        setLoaded(true);
      });
  }, []);

  if (!riskRow || !templateBundle || !loaded) {
    return <LoadingRiskRowTile ref={replacerRef} />;
  }

  const overview = calculateRowOverview(riskRow.cells, riskRow.positionHistory, false, templateBundle.riskRowTemplate);

  const residual = calculateRowOverview(riskRow.cells, riskRow.positionHistory, true, templateBundle.riskRowTemplate);

  return (
    <div className="risk_row_container risk_row_tile">
      <div className="risk_row_container_two">
        <div>
          {overview != null && (
            <MatrixInlineView
              id={overview.col.identifier}
              column={overview.col}
              riskrow={riskRow}
              isRed={overview.isRed}
              isYellow={overview.isYellow}
              isGrey={!overview.isRed && !overview.isYellow}
              cell={overview.cell}
            />
          )}
        </div>
      </div>

      <div className="step_indent_container">
        <div>
          {riskRow.measures.map((measure) => {
            return getStatusIcon(measure, templateBundle.measuresWorkflowProcess);
          })}
        </div>
        <div className="measure_caption">
          <FormattedMessage
            id="riskrowinline.measure"
            defaultMessage="{count} measure{predicate}"
            values={{
              count:
                riskRow.measures.length === 0 ? (
                  <FormattedMessage id="riskrowinline.count" defaultMessage="No" />
                ) : (
                  riskRow.measures.length
                ),
              predicate:
                riskRow.measures.length === 1 ? (
                  ''
                ) : (
                  <FormattedMessage id="riskrowinline.predicate" defaultMessage="s" />
                )
            }}
          />
          <ul style={{display: 'grid', paddingInlineStart: '0px', paddingBlockStart: '5px'}}>
            <FormattedMessage id="EnhancedTable.placement" defaultMessage="Placement:" />
            <PlacementInfo versionId={versionId} />

            <FormattedMessage id="riskrowinline.responsible" defaultMessage="Responsible:" />
            <ResponsibleInfo versionId={versionId} />

            <FormattedMessage id="riskrowinline.origin" defaultMessage="Origin:" />
            <OriginInfo versionId={versionId} />
          </ul>
        </div>
      </div>
      <div className="step_indent_container_two">
        <div>
          {residual && riskRow.measures.length > 0 && (
            <MatrixInlineView
              id={residual.col.identifier}
              column={residual.col}
              riskrow={riskRow}
              isRed={residual.isRed}
              isYellow={residual.isYellow}
              isGrey={!residual.isRed && !residual.isYellow}
              cell={residual.cell}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function PlacementInfo({ versionId }) {
  const [placement, setPlacement] = useState(null);
  const [loadingPlacement, setLoadingPlacement] = useState(true);

  useEffect(() => {
    handleQuery(`/catalog/getPlacement/${versionId}?useNames=true`)
      .then((catalogPlacementData) => {
        setPlacement(catalogPlacementData);
        setLoadingPlacement(false);
      })
      .catch(() => {
        setLoadingPlacement(false);
      });
  }, []);

  return (
    <>
      {loadingPlacement ? (
        <li style={{ marginRight: '10px', display: 'inline-flex', color: '#80A2AC', paddingLeft: '10px' }}>
          <FormattedMessage id="EnhancedTable.searchingForPlacement" defaultMessage="Loading placement" />
        </li>
      ) : (
        <>
          {placement ? (
            <>
              {placement.map((p) => (
                <li style={{ marginRight: '10px', display: 'inline-block', color: '#80A2AC', paddingLeft: '10px' }}>
                  {p}
                </li>
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

function ResponsibleInfo({ versionId }) {
  const [loadingResponsible, setLoadingResponsible] = useState(true);
  const [uniqueResponsible, setUniqueResponsible] = useState([null]);

  useEffect(() => {
    handleQuery(`/measure/getMeasure/${versionId}`)
      .then((res) => {
        const responsible = res?.measures
          ?.map((measure) => measure.responsible?.name)
          .filter((name) => name !== undefined);
        setUniqueResponsible([...new Set(responsible)]);
        setLoadingResponsible(false);
      })
      .catch(() => {
        setLoadingResponsible(false);
      });
  }, []);

  return (
    <>
      {loadingResponsible ? (
        <li style={{ marginRight: '10px', display: 'inline-flex', color: '#80A2AC', paddingLeft: '10px' }}>
          <FormattedMessage id="riskrowinline.loadingResponsible" defaultMessage="Loading placement" />
        </li>
      ) : (
        <>
          {uniqueResponsible && (
            <>
              {uniqueResponsible.map((p) => (
                <li style={{ marginRight: '10px', display: 'inline-flex', color: '#80A2AC', paddingLeft: '10px' }}>
                  {p}
                </li>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
}

function OriginInfo({ versionId }) {
  const [loadingOrigin, setLoadingOrigin] = useState(true);
  const [origin, setOrigin] = useState(null);

  useEffect(() => {
    getOrigin(versionId)
      .then((origin) => {
        setOrigin(origin.reverse());
        setLoadingOrigin(false);
      })
      .catch(() => {
        setLoadingOrigin(false);
      });
  }, []);

  return (
    <div>
      {loadingOrigin ? (
        <li style={{marginRight: '10px', display: 'inline-flex', color: '#80A2AC', paddingLeft: '10px'}}>
          <FormattedMessage id="riskrowinline.loadingOrigin" defaultMessage="Loading origin"/>
        </li>
      ) : (
        <>
          {origin && origin.length > 0 && (
            <>
              {origin.map((info) => (
                <li style={{marginRight: '10px', display: 'inline-flex', color: '#80A2AC', paddingLeft: '10px'}}>
                  {info.name}
                </li>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default withTile(TileIcon, OverviewTile, ElementContentOverviewTile, null, null, RiskRowTile);
