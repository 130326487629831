import React, { useCallback, useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Button, ButtonGroup } from '@mui/material';
import { useResizeObserver } from '@zert-packages/react/hooks/useResizeObserver';
import { styled } from '@mui/material/styles';
import { AddFileButtonLimited } from '../shared/AddFileButton/AddFileButton';
import CreateNewSelectionDropdown from "../CreateNewSelectionDropdown";

const useToolbarStyles = makeStyles((theme) => ({
  toolbar: {
    gridArea: 'toolbar',
    overflowX: 'scroll',
    width: '100%',
    padding: '0 5px',
    '& *': {
      margin: '2px'
    }
  }
}));

const defaultButtonGroupStyle = {
  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    border: 'none'
  }
};

function AdaptableToolbar({
  actions,
  selected,
  selectedNode,
  snackbarComponent,
  showElementActions,
  catalogIndex = null,
  reloadElement,
  buttonGroupStyle = defaultButtonGroupStyle,
  disableUpload = false,
  showCut = false,
  showFileUpload = false,
  showCreateLink = false,
  ...other
}) {
  const classes = useToolbarStyles();
  const [hideText, setHideText] = useState<boolean>(false);
  const [fullWidth, setFullWidth] = useState<number>(0);
  const buttonGroupRef = useRef<HTMLDivElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const { elementActions, performActionHandler, toolbarActions } = actions({
    selected,
    catalogIndex,
    reloadElement,
    showCut,
    showFileUpload,
    showCreateLink,
    ...other
  });
  //    : actions({ selected, reloadElement, ...other });
  const actionMenu = showElementActions ? toolbarActions : elementActions;

  const [tableToolbarWidth] = useResizeObserver(divRef);

  const StyledButtonGroup = styled(ButtonGroup)(buttonGroupStyle);

  const normalActionButton = (action, hideText) => {
    return hideText ? (
      <IconButton color="primary" size="small" onClick={(e) => performActionHandler(selected)(action.id)}>
        {action.icon}
      </IconButton>
    ) : (
      <Button size="small" startIcon={action.icon} onClick={(e) => performActionHandler(selected)(action.id)}>
        {action.name}
      </Button>
    );
  };

  const actionMenuMapper = useCallback(
    (action) => {
      return (
        <Tooltip key={`actionMenu-${action.id}`} title={action.name} disableHoverListener={true} >
          <div>
            {(() => {
              if (action.isUpload) {
                return (
                  <AddFileButtonLimited
                    label={action.name}
                    size="small"
                    onlyIcon={hideText}
                    handleUpload={(fileData, fileName) => performActionHandler(selected)(action.id, fileData, fileName)}
                    icon={action.icon}
                    disabled={disableUpload}
                  />
                );
              } else if (action.isDropdown) {
                return (
                  <CreateNewSelectionDropdown
                    catalogNode={selectedNode}
                    label={action.name}
                    icon={action.icon}
                    catalogIndex={catalogIndex}
                    size="small" />
                );
              } else {
                return normalActionButton(action, hideText);
              }
            })()}
          </div>
        </Tooltip>
      );
    },
    [hideText, selected, disableUpload]
  );

  const selectViewMode = () => {
    const visibleWidth = divRef.current?.getBoundingClientRect()?.width;
    const scrollWidth = buttonGroupRef?.current?.scrollWidth;
    if (!hideText && scrollWidth) {
      setFullWidth(scrollWidth);
    }
    const compareWidth = fullWidth || scrollWidth;
    if (visibleWidth && compareWidth && compareWidth > visibleWidth) {
      setHideText(true);
    } else {
      setHideText(false);
    }
  };

  useEffect(selectViewMode, [tableToolbarWidth]);
  useEffect(() => {
    selectViewMode();
  }, []);

  return (
    <div className={classes.toolbar} ref={divRef}>
      <StyledButtonGroup variant="text" ref={buttonGroupRef}>
        {actionMenu && actionMenu.map(actionMenuMapper)}
      </StyledButtonGroup>
    </div>
  );
}

export default AdaptableToolbar;
