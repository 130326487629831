import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { handleBlock } from '@zert-packages/actions/commonReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 5,
    height: 30,
    alignItems: 'center',
    margin: '5px 0px'
  },
  itemMenu: {
    cursor: 'pointer',
    padding: '5px 10px',
    borderRight: '1px solid #919191',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#05364A'
    }
  }
}));

function BlockEditorMenu() {
  const cl = useStyles();
  const { block } = useSelector((state) => state);
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleAction = (type) => {
    dispatch(handleBlock(type, 'blockEditorActionType'));
  };

  const dataMenu = [
    {
      name: intl.formatMessage({ id: 'ElementTile.BlockEditor.Header.ImportText', defaultMessage: 'Import text' }),
      type: 'importText'
    },
    {
      name: intl.formatMessage({ id: 'ElementTile.BlockEditor.Header.ImportTable', defaultMessage: 'Import table' }),
      type: 'importTable'
    }
  ];

  return (
    <ul className={cl.root}>
      {dataMenu.map((el, i) => (
        <li key={i} className={cl.itemMenu} onClick={() => handleAction(el.type)}>
          {el.name}
        </li>
      ))}
    </ul>
  );
}

export default BlockEditorMenu;
