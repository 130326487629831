import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TreeView } from '@mui/lab';
import makeStyles from '@mui/styles/makeStyles';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderTwoToneIcon from '@mui/icons-material/FolderTwoTone';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import PageviewTwoToneIcon from '@mui/icons-material/PageviewTwoTone';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import { useSelector } from 'react-redux';
import { addId } from '@zert-packages/utils';
import { useParams } from 'react-router-dom';
import SkeletonLoading from './SkeletonLoading';
import TreeItemParentsBranch from './TreeItemParentsBranch';
import { PanelContext } from '../../InfoElementWrapper/InfoElementWrapper';
import { getApiTree } from '../API';

function handleIcons(item, expanded) {
  switch (item) {
    case 'application/zert-folder':
      return expanded && expanded.length > 0 ? <FolderOpenIcon /> : <FolderTwoToneIcon />;
    case 'application/zert-search-folder':
      return expanded && expanded.length > 0 ? <PageviewOutlinedIcon /> : <PageviewTwoToneIcon />;
    default:
      return <SettingsEthernetIcon />;
  }
}

const useStyles = makeStyles((theme) => ({
  rootList: {
    height: '100%',
    '& .MuiTreeItem-group': {
      marginLeft: '8px'
    },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: '#fff'
    }
  }
}));

function Tree({ getRootNode, selectedWord, structureMain, blockClick, context, overflowHidden }) {
  const [rootNode, setRootNode] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const cl = useStyles();
  const { versionId } = useParams();
  const { mylocale, myLanguage } = useSelector((state) => state);
  const { values } = context || useContext(PanelContext);
  const copyBlock = values?.copyBlock;

  const handleToggle = (event, nodeIds) => {
    if (blockClick) {
      return;
    }

    const adobe = nodeIds.find((el) => +el === 1);
    if (!adobe) {
      return setExpanded(['1', ...nodeIds]);
    }
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    if (blockClick) {
      return;
    }
    setSelected(nodeIds);
  };

  const getTree = async () => {
    return getApiTree(versionId, mylocale);
  };

  const handleGetRootNode = useCallback(async () => {
    if (!rootNode && mylocale) {
      const resRootNode = await (getRootNode ? getRootNode() : getTree());
      if (resRootNode) {
        setRootNode(addId(resRootNode));
      }
    }
  }, [rootNode, mylocale]);

  useEffect(() => {
    if (myLanguage) {
      handleGetRootNode();
    }
  }, [handleGetRootNode, selectedWord, myLanguage]);

  useEffect(() => {
    if (values && values.treeChildren && values.treeChildren.length > 0) {
      setRootNode(values.treeChildren);
    }
  }, [values]);

  useEffect(() => {
    if (values?.treeItemDelete) {
      const id = values?.treeItemDelete?.identifier;
      const findDeleteItem = rootNode.find((el) => el.identifier === id);
      if (!findDeleteItem) {
        return;
      }
      setExpanded((pre) => pre.filter((el) => el !== id));
      setSelected('');
      setRootNode((pre) => pre.filter((el) => el.identifier !== id));
    }
  }, [values?.treeItemDelete]);

  useEffect(() => {
    if (values?.treeItem) {
      setSelected(values.treeItem.identifier);
      setExpanded((pre) => [...pre, values.treeItem.identifier]);
    }
  }, [values?.treeItem]);

  const addItem = (items, item, index) => {
    return items.reduce((acu, cur, idx) => {
      if (idx === index) {
        return [...acu, item, cur];
      }
      return [...acu, cur];
    }, []);
  };

  useEffect(() => {
    if (copyBlock?.insert && copyBlock?.blockItems?.investmentLevelBlock < 2) {
      const res = addItem(rootNode, copyBlock.blockItems, copyBlock.findIndex);
      setRootNode(res);
    }
  }, [copyBlock?.blockItems]);

  useEffect(() => {
    setExpanded(['1']);
    if (structureMain) {
      setSelected(['1']);
    }
  }, []);

  if (rootNode && rootNode.length === 0) {
    return <div />;
  }

  return (
    <>
      {!rootNode ? (
        <SkeletonLoading />
      ) : (
        rootNode && (
          <TreeView
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            className={cl.rootList}
          >
            {mylocale && rootNode && (
              <TreeItemParentsBranch
                context={context}
                rootNode={rootNode}
                expand={expanded}
                setExpanded={setExpanded}
                selected={selected}
                structureMain={structureMain}
              />
            )}
          </TreeView>
        )
      )}
    </>
  );
}

export default Tree;
