import { fetchSTORE_Error, handlePost, handleText } from '@zert-packages/actions/api';
import {
  COOKIE_SETTINGS_FETCH,
  COOKIE_SETTINGS_FETCH_DONE,
  COOKIE_SETTINGS_STORE_DONE,
  COOKIE_SETTINGS_STORE_START
} from '@zert-packages/components/common/Cookie/CookieSettingsReducers';

export function storeCookieSettings(data) {
  return function (dispatch) {
    handlePost('/common/setCookieSettings/', data)
      .then((cookieSettings) => {
        dispatch(COOKIE_SETTINGS_STORE_DONE(cookieSettings));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(COOKIE_SETTINGS_STORE_START());
  };
}

let cookieSettingsRequestPromise = false;
export function getCookieSettings() {
  return function (dispatch) {
    if (!cookieSettingsRequestPromise) {
      cookieSettingsRequestPromise = true;
      handleText('/common/getCookieSettings')
        .then((cookieSettings) => {
          dispatch(COOKIE_SETTINGS_FETCH_DONE(cookieSettings));
          cookieSettingsRequestPromise = false;
        })
        .catch((error) => {
          dispatch(fetchSTORE_Error(error));
          cookieSettingsRequestPromise = false;
        });
      return dispatch(COOKIE_SETTINGS_FETCH);
    }
  };
}
