import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { handleActionChildBlockHeader } from '@zert-packages/actions/commonReducer';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { getSideBarMenu } from '../../InfoElement/helpers/getSideBarMenu';
import BurgerMenu from './BurgerMenu';
import { findSizeMenu } from '../helpers/findSizeMenu';
import undoAction from '../helpers/undoAction';
import redoAction from '../helpers/redoAction';
import TooltipCustom from '../../TooltipCustom';
import HistoryStepper from './HistoryStepper';

const useStyles = makeStyles((theme) => ({
  editorBlockHead: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '70px auto 44px 20px',
    alignItems: 'center',
    columnGap: 7
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    maxWidth: 70,
    maxHeight: 20,
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1
  },
  action: {
    display: 'flex',
    gap: 5
  },
  actionItem: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    cursor: 'pointer'
  },
  wrapperStepper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  step: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const dataSizeTabs = {
  width: null,
  size: 100
};

function EditorBlockHead() {
  const cl = useStyles();
  const dispatch = useDispatch();
  const { block } = useSelector((state) => state);
  const [sizeTabs, setSizeTabs] = useState(dataSizeTabs);
  const [blockHead, setBlockHead] = useState({
    showHistory: false
  });
  const typeSelectedBlock = block?.selectedItem && block.selectedItem?.elementInfo?.mimeType.split('/').pop();
  const ref = useRef(null);
  const selectedItem = block?.selectedItem;
  const menu = getSideBarMenu(selectedItem);

  const handleResize = () => {
    const width = ref.current.offsetWidth;
    const size = findSizeMenu(width);
    setSizeTabs((pre) => ({
      ...pre,
      width,
      size
    }));
  };

  const handleOpenHistory = (e, name) => {
    setBlockHead((pre) => ({ ...pre, [name]: e.currentTarget }));
  };

  const handleBlockHead = (name, val) => {
    setBlockHead((pre) => ({ ...pre, [name]: val }));
  };

  const handelAction = (type) => {
    dispatch(handleActionChildBlockHeader(type));
  };

  // useEffect(() => {
  //        if (!ref.current) return
  //        handleResize();
  //        window.addEventListener('resize', handleResize);
  //        return () => {
  //            window.removeEventListener('resize', handleResize);
  //        };
  //    }, [ref.current]);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;
    handleResize();
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });
    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, [ref.current]);

  if (!selectedItem) {
    return <div />;
  }

  return (
    <div style={{ width: '100%' }}>
      <div className={cl.editorBlockHead}>
        <div className={cl.title}>{selectedItem.elementInfo?.name}</div>

        <div className={cl.action}>
          {menu.slice(0, sizeTabs.size).map((el, i) => (
            <div key={i} className={cl.actionItem} onClick={(e) => handelAction(el.type)}>
              {el?.icon} {el.name}
            </div>
          ))}

          {sizeTabs.size < 0 && (
            <div style={{ marginLeft: 5 }}>
              <BurgerMenu dropMenu={menu.slice(sizeTabs.size)} action={handelAction} />
            </div>
          )}
        </div>

        <div className={cl.wrapperStepper}>
          <div className={cl.actionItem}>
            <TooltipCustom title={<FormattedMessage id="ElementTile.Actions.Undo" defaultMessage="Undo" />}>
              <UndoIcon onClick={(e) => undoAction(e, dispatch, typeSelectedBlock)} />
            </TooltipCustom>
          </div>

          <div className={cl.actionItem}>
            <TooltipCustom title={<FormattedMessage id="ElementTile.Actions.Redo" defaultMessage="Redo" />}>
              <RedoIcon onClick={(e) => redoAction(e, dispatch, typeSelectedBlock)} />
            </TooltipCustom>
          </div>
        </div>
        <KeyboardDoubleArrowDownIcon
          style={{ cursor: 'pointer' }}
          onClick={(e) => handleOpenHistory(e, 'showHistory')}
        />
        <HistoryStepper anchorEl={blockHead.showHistory} close={handleBlockHead} type="showHistory" />
      </div>
      <div id="test" style={{ width: '100%' }} ref={ref} />
    </div>
  );
}

export default EditorBlockHead;
