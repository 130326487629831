import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { delegateTasks, informUsers as informUserAPI, performTaskAPI } from '@zert-packages/actions/actionplan';
import { useViewport } from '@zert-packages/utils/ViewportProvider';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
  archiveElements,
  copyElements,
  createLinkInExplorer,
  duplicateCommon,
  generateReportDialog,
  pasteCopiedElements,
  removeElements,
  rename,
  uploadFileToExplorer
} from '@zert-packages/plugins/ExplorerPlugin/servicesMidleware';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { isTask } from '@zert-packages/utils/isTask';
import { getPreviewFunc, getPublishFunc } from '@zert-packages/actions/api';
import isReadOnly from '@zert-packages/utils/isReadOnly';
import EditSearchFolderDialog from '@zert-packages/components/ElementTile/EditSearchFolderDialog';
import { getSearchFolderApi } from '@zert-packages/plugins/ExplorerPlugin/API';
import { convertFromBackendCriteria } from '@zert-packages/plugins/ExplorerPlugin/helpers/setCriteria';
import { clearAndPrepareSearch } from '@zert-packages/plugins/ExplorerPlugin/explorerReducers';
import { useHistory } from 'react-router-dom';
import { defaultKey, getFillFunction } from '@zert-packages/utils/fillTemplateString';
import { v4 as uuidv4 } from 'uuid';
import HoverWithSnackbarLoaderDialog from '../common/dialogs/HoverWithSnackbarLoaderDialog';
import { showSnackbarChangesSaved, showSnackbarError } from '../Snackbars';
import PasswordVerifierDialog from '../common/dialogs/PasswordVerifierDialog';
// import PreviewWizardDialog from "../../plugins/PreviewPlugin/PreviewWizardDialog/PreviewWizardDialog";
// import {generatePreviewReport} from "../../plugins/PreviewPlugin/servicesMiddlewares";
// import {createPublishing, createPublishingReport} from "../../plugins/PublishingPlugin/servicesMiddlewares";
import InformTasksParametersDialog from '../common/dialogs/InformTasksParametersDialog';
import {
  actionsKey,
  actionsNamesKey,
  ARCHIVE_ACTION,
  CREATE_DOC,
  CREATE_NEW,
  CUT_ACTION,
  DELETE_ACTION,
  DUPLICATE_ACTION,
  EDIT_SEARCH_FOLDER_ACTION,
  ELEMENT_ACTIONS,
  INFORM_ACTIONS,
  OPEN_ACTION,
  OPEN_IN_NEW_ACTION,
  OVERVIEW_ACTIONS,
  PASTE_ACTION,
  PREVIEW_ACTIONS,
  PUBLISH_ACTIONS,
  REPORT_ACTIONS,
  TASK_ACTIONS,
  TASK_ACTIONS_TYPES,
  UNARCHIVE_ACTION,
  UPLOAD_DOC
} from './shared/ELEMENT_TILE_CONTS';
import DelegateeTasksParametersDialog from '../common/dialogs/DelegateeTasksParametersDialog';
import { usePortal } from '@zert-packages/utils/PortalProvider';
import Portal from '@zert-packages/react/interfaces/Portals';
import dialogCustom from '../common/dialogCustom';
import CreateNewDocument from '@zert-packages/plugins/DocumentPlugin/CreateNewDocument';

const fillPath = getFillFunction(defaultKey);

const checkOwnerPassword = (next) => (state) => {
  if (!state.translation.ownerPasswordRequired) return next(state);
  renderInExceptionRoot(PasswordVerifierDialog, {
    title: <FormattedMessage id="ElementTile.OwnerPassword" defaultMessage="Provider owner password" />,
    onConfirm: () => next(state)
  });
};

const checkOwnPassword = (next) => (state) => {
  if (!state.translation.passwordRequired) return next(state);
  renderInExceptionRoot(PasswordVerifierDialog, {
    onConfirm: () => next(state)
  });
};

const isActionPlan = () => document.location.pathname === '/action-plan';

const isSearch = (hasSearchRes) => hasSearchRes && document.location.pathname === '/search';

const useElementActions = ({
  workflowTemplate,
  reloadElement,
  selected,
  overviewActions,
  showCut,
  showFileUpload,
  showCreateLink,
  catalogIndex,
  productSpecificActions
}) => {
  const stateToSend = useSelector((state) => state);
  const myUser = useSelector((state) => state.myuser);
  const { permits } = useSelector((state) => state);
  const hasSearchRes = useSelector((state) => state.CORE?.searchResult != null);

  const [uuid, setUuid] = useState(null);
  let portal;
  try {
    portal = usePortal();
  } catch (error) {
    console.error('Error accessing portal:', error);
    portal = null;
  }

  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const history = useHistory();
  const { isMobile } = useViewport();

  const freezeScreen = () => renderInExceptionRoot(HoverWithSnackbarLoaderDialog, { snackbar });
  const getActivity = (transition) => workflowTemplate.activities.find((activity) => activity.id === transition.to.id);

  const getTransition = (workflowId) => workflowTemplate.transitions.find((transition) => transition.id === workflowId);

  useEffect(() => {
    try {
      if (!stateToSend || !uuid) {
        return;
      }

      localStorage.setItem(uuid, JSON.stringify(stateToSend));
      setUuid(null);
    } catch (error) {
      console.error('Error storing data in localStorage:', error);
    }
  }, [uuid, stateToSend]);

  const callbackReloadList = (actionType, freezeOff) => (onFinishState) => {
    if (freezeOff) {
      freezeOff();
    }
    showSnackbarChangesSaved(snackbar);
    if (reloadElement && TASK_ACTIONS_TYPES.inform !== actionType) {
      selected.map((selection) => {
        reloadElement({
          element: selection,
          actionType,
          onFinishState
        });
      });
    }
  };

  const handleSnackbarError = (freezeOff) => (e) => {
    if (freezeOff) freezeOff();
    showSnackbarError(snackbar, e.message);
  };

  const isAdmin = () => {
    const role = get(myUser, 'role', 'default');
    return {
      administrator: true,
      'task-administrator': true,
      default: false
    }[role];
  };

  const canArchive = () => {
    return permits['zert-administration/archive'] && permits['zert-administration/archive'] === true;
  };

  const canDelete = () => {
    return permits['zert-administration/delete'] && permits['zert-administration/delete'] === true;
  };

  const informUser = (versionId) =>
    renderInExceptionRoot(InformTasksParametersDialog, {
      onConfirm: (login, message) => {
        const freezeOff = freezeScreen();
        informUserAPI(login, versionId, message)
          .then(callbackReloadList(TASK_ACTIONS_TYPES.inform, freezeOff))
          .catch(handleSnackbarError(freezeOff));
      }
    });

  const generateReport = () => dispatch(generateReportDialog(selected, catalogIndex, null));

  const generatePreview = (element) => {
    if (getPreviewFunc()) {
      dispatch(getPreviewFunc()(element, -1));
    }
  };

  const publishElement = (element) => {
    if (getPublishFunc()) {
      dispatch(getPublishFunc()(element, -1));
    }
  };

  const delegateTask = (versionIds) =>
    renderInExceptionRoot(DelegateeTasksParametersDialog, {
      onConfirm: (login, message) => {
        const freezeOff = freezeScreen();
        delegateTasks(login, versionIds, message)
          .then(callbackReloadList(TASK_ACTIONS_TYPES.delegate, freezeOff))
          .catch(handleSnackbarError(freezeOff));
      }
    });

  const deleteElements = () => {
    dispatch(removeElements(selected, callbackReloadList(TASK_ACTIONS_TYPES.delete)));
  };

  const renameElement = (element) => {
    dispatch(rename(element, callbackReloadList(TASK_ACTIONS_TYPES.delete)));
  };

  const finishWorkflowAction = ({ translation, versionId }) => {
    const freezeOff = freezeScreen();
    performTaskAPI(versionId, getActivity(translation).id).then(callbackReloadList('perform', freezeOff));
  };

  function workflowActionsHandler(translationId, versionId, workflowTemplateId, task) {
    const translation = getTransition(translationId);
    if (!translation) return;

    const queueActions = [checkOwnerPassword, checkOwnPassword];

    queueActions.reduce(
      (prevAction, nextAction) => nextAction(prevAction),
      finishWorkflowAction
    )({
      translation,
      versionId
    });
  }

  const createWorkflowActions = () => {
    const listOfTaskActions = [...TASK_ACTIONS];
    // const { workflowTemplate, element } = props;

    if (!workflowTemplate || !selected[0].properties) {
      return listOfTaskActions;
    }

    const workflowTemplateActions = {
      keys: selected[0].properties[actionsKey],
      names: selected[0].properties[actionsNamesKey]
    };

    if (workflowTemplateActions && workflowTemplateActions.keys && workflowTemplateActions.keys.isArray) {
      selected[0].properties[actionsKey].map((action, index) => {
        listOfTaskActions.push({
          id: action,
          name: workflowTemplateActions.names[index]
        });
      });
    } else {
      listOfTaskActions.push({
        id: workflowTemplateActions.keys,
        name: workflowTemplateActions.names
      });
    }

    return listOfTaskActions;
  };

  const archiveElement = (archive) => {
    dispatch(archiveElements(archive, selected, callbackReloadList(TASK_ACTIONS_TYPES.archive)));
  };

  const duplicateElement = (element) =>
    dispatch(duplicateCommon(element, showCut, callbackReloadList(TASK_ACTIONS_TYPES.duplicate)));

  const copyElement = (isCut) => {
    dispatch(copyElements(selected, isCut));
  };
  const openElement = (selected, newWindow) => {
    if (newWindow) {
      const newUuid = uuidv4();
      setUuid(newUuid);
      window.open(fillPath(selected, `${getPluginByMimeType(selected.mimeType).openpath}?sync=${newUuid}`), '_blank');
    } else {
      history.push(fillPath(selected, getPluginByMimeType(selected.mimeType).openpath));
    }
  };

  const pasteElement = () => {
    const freezeOff = freezeScreen();
    dispatch(pasteCopiedElements(callbackReloadList(TASK_ACTIONS_TYPES.paste, freezeOff)));
  };

  const editSearchFolder = (selected) => {
    console.log(selected);
    const initFunc = (id) => {
      return getSearchFolderApi(id).then((v) => {
        console.log(v);
        const res = v.criterias;
        console.log(res);
        const resConverted = convertFromBackendCriteria(res);
        console.log(convertFromBackendCriteria(res));
        return dispatch(clearAndPrepareSearch(resConverted));
      });
    };
    return renderInExceptionRoot(EditSearchFolderDialog, {
      id: selected.id,
      initFunction: initFunc,
      originalName: selected.name
    });
  };

  const performActionHandler = (selected) => (actionId, fileData) => {
    if (actionId === TASK_ACTIONS_TYPES.inform) return informUser(selected[0].versionId);
    if (actionId === TASK_ACTIONS_TYPES.open) return openElement(selected[0], false);
    if (actionId === TASK_ACTIONS_TYPES.open_in_new) return openElement(selected[0], true);
    if (actionId === TASK_ACTIONS_TYPES.delegate)
      return delegateTask(selected.length > 1 ? selected.map((task) => task.versionId) : [selected[0].versionId]);
    if (actionId === TASK_ACTIONS_TYPES.delete) return deleteElements();
    if (actionId === TASK_ACTIONS_TYPES.rename) return renameElement(selected[0]);
    if (actionId === TASK_ACTIONS_TYPES.archive) return archiveElement(true);
    if (actionId === TASK_ACTIONS_TYPES.unarchive) return archiveElement(false);
    if (actionId === TASK_ACTIONS_TYPES.duplicate) return duplicateElement(selected[0]);
    if (actionId === TASK_ACTIONS_TYPES.copy) return copyElement(false);
    if (actionId === TASK_ACTIONS_TYPES.cut) return copyElement(true);
    if (actionId === TASK_ACTIONS_TYPES.paste) return pasteElement();
    if (actionId === TASK_ACTIONS_TYPES.preview) return generatePreview(selected[0]);
    if (actionId === TASK_ACTIONS_TYPES.publish) return publishElement(selected[0]);
    if (actionId === TASK_ACTIONS_TYPES.report) return generateReport();
    if (actionId === TASK_ACTIONS_TYPES.editSearchFolder) return editSearchFolder(selected[0]);
    if (actionId === TASK_ACTIONS_TYPES.upload_doc) return dispatch(uploadFileToExplorer(fileData));
    if (actionId === TASK_ACTIONS_TYPES.create_doc) {
      dialogCustom(CreateNewDocument, {
        onCreate: async (res) => {
          dispatch(createLinkInExplorer(res));
        }
      });
      return;
    }
    if (productSpecificActions.find((action) => action.id == actionId))
      return productSpecificActions.find((action) => action.id == actionId).executeAction(selected[0], dispatch);
    return workflowActionsHandler(actionId, selected[0].versionId, selected[0].workflowTemplateId, selected[0]);
  };
  const { copiedElements, selectedNode } = useSelector((state) => state.CORE);
  const searchFolderMIME = 'application/zert-search-folder';

  const elementActions = [
    ...(showCreateLink && selectedNode && !(selectedNode.mimeType === searchFolderMIME) ? CREATE_NEW : []),
    ...(showFileUpload && selectedNode && !(selectedNode.mimeType === searchFolderMIME) ? UPLOAD_DOC : []),
    ...(showCreateLink && selectedNode && !(selectedNode.mimeType === searchFolderMIME) ? CREATE_DOC : []),
    ...(selected.length > 0 ? ELEMENT_ACTIONS : []),
    ...(selected.length > 0 || isActionPlan() || isSearch(hasSearchRes) ? REPORT_ACTIONS : []),
    ...(selected.length > 0 && showCut && !(selectedNode.mimeType === searchFolderMIME) ? [CUT_ACTION] : []),
    ...(showCut &&
    copiedElements &&
    copiedElements.length > 0 &&
    selectedNode &&
    !(selectedNode.mimeType === searchFolderMIME)
      ? [PASTE_ACTION]
      : []),
    ...(selected.length == 1 && isTask(selected[0].mimeType)
      ? createWorkflowActions()
      : selected.length > 1 && selected.every((selection) => isTask(selection.mimeType))
      ? TASK_ACTIONS
      : []),
    ...(selected.length == 1 && window.ISCLM ? PREVIEW_ACTIONS : []),

    ...(selected.length == 1 &&
    window.ISCLM &&
    (selected[0].approvedAt || selected.label != '1.1') &&
    getPluginByMimeType(selected[0].mimeType).allowPublishing
      ? PUBLISH_ACTIONS
      : []),
    ...(selected.length > 0 && (isAdmin() || canArchive()) && !selected[0].archivedAt ? [ARCHIVE_ACTION] : []),
    ...(selected.length > 0 && (isAdmin() || canArchive()) && selected[0].archivedAt ? [UNARCHIVE_ACTION] : []),
    ...(selected.length > 0 && (isAdmin() || canDelete()) ? [DELETE_ACTION] : []),

    ...(selected.length == 1 && !isReadOnly({ info: selected[0] }, myUser) ? OVERVIEW_ACTIONS : []),
    ...(selected.length == 1 ? DUPLICATE_ACTION : []),
    ...(selected.length == 1 ? INFORM_ACTIONS : []),
    ...(productSpecificActions && selected.length == 1 ? productSpecificActions : []),
    ...(selected.length == 1 && selected[0].mimeType === searchFolderMIME ? EDIT_SEARCH_FOLDER_ACTION : []),
    ...(selected.length > 0
      ? isMobile || (portal && portal.identifier === Portal.RM)
        ? [OPEN_IN_NEW_ACTION]
        : [OPEN_ACTION]
      : [])
  ];

  const toolbarActions = [
    ...(selected.length > 0 ? ELEMENT_ACTIONS : []),
    ...(selected.length > 0 ? REPORT_ACTIONS : []),
    ...(selected.length == 1 && window.ISCLM && getPluginByMimeType(selected[0].mimeType).allowPublishing
      ? PREVIEW_ACTIONS
      : []),
    ...(selected.length == 1 &&
    window.ISCLM &&
    (selected[0].approvedAt || selected.label != '1.1') &&
    getPluginByMimeType(selected[0].mimeType).allowPublishing
      ? PUBLISH_ACTIONS
      : []),
    ...(selected.length > 0 && showCut ? [CUT_ACTION] : []),
    ...(showCut && copiedElements && copiedElements.length > 0 ? [PASTE_ACTION] : []),
    ...(selected.length > 0 && (isAdmin() || canArchive()) && !selected[0].archivedAt ? [ARCHIVE_ACTION] : []),
    ...(selected.length > 0 && (isAdmin() || canArchive()) && selected[0].archivedAt ? [UNARCHIVE_ACTION] : []),
    ...(selected.length > 0 && (isAdmin() || canDelete()) ? [DELETE_ACTION] : []),
    ...(selected.length > 0
      ? isMobile || (portal && portal.identifier === Portal.RM)
        ? [OPEN_IN_NEW_ACTION]
        : [OPEN_ACTION]
      : [])
  ];

  const getMenuActions = (row) => [
    ...ELEMENT_ACTIONS,
    ...REPORT_ACTIONS,
    ...(getPluginByMimeType(row.mimeType).allowPublishing ? PREVIEW_ACTIONS : []),
    ...((row.approvedAt || row.label != '1.1') && getPluginByMimeType(row.mimeType).allowPublishing
      ? PUBLISH_ACTIONS
      : []),
    ...(showCut && overviewActions ? [CUT_ACTION] : []),
    ...((isAdmin() || canDelete()) && overviewActions ? [DELETE_ACTION] : []),
    ...(isTask(row.mimeType) && overviewActions ? createWorkflowActions() : []),
    ...(selected.length > 0 && (isAdmin() || canArchive()) && !selected[0].archivedAt ? [ARCHIVE_ACTION] : []),
    ...(selected.length > 0 && (isAdmin() || canArchive()) && selected[0].archivedAt ? [UNARCHIVE_ACTION] : []),
    ...(overviewActions ? OVERVIEW_ACTIONS : []),
    ...(overviewActions ? DUPLICATE_ACTION : []),
    ...INFORM_ACTIONS,
    OPEN_IN_NEW_ACTION
  ];

  return { elementActions, toolbarActions, getMenuActions, performActionHandler };
};

export default useElementActions;
