import { createTheme } from '@mui/material/styles';

const LogMaxTheme = () => {
  return createTheme({
    palette: {
      primary: {
        light: '#595959',
        main: '#262626',
        dark: '#0f0f0f',
        contrastText: '#FBFCFD'
      },
      secondary: {
        light: '#595959',
        main: '#262626',
        dark: '#c70061',
        contrastText: '#fff'
      },
      warning: {
        light: '#ffd433',
        main: '#FFCA00',
        dark: '#b28d00',
        contrastText: '#FBFCFD'
      },
      error: {
        light: '#ff8886',
        main: '#FF6B68',
        dark: '#b24a48',
        contrastText: '#FBFCFD'
      },
      success: {
        light: '#67e7b5',
        main: '#42E2A3',
        dark: '#2e9e72',
        contrastText: '#FBFCFD'
      }
    },
    typography: {
      h1: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontWeight: 500,
        fontSize: '1.5rem',
        color: '#404040'
      },
      h2: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontWeight: 500,
        fontSize: '1.3rem',
        color: '#404040'
      },
      h3: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontWeight: 500,
        fontSize: '1.1rem',
        color: '#404040'
      },
      h4: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontWeight: 500,
        fontSize: '1rem',
        color: '#404040'
      },
      h5: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontWeight: 500,
        fontSize: '1rem',
        color: '#404040'
      },
      h6: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontWeight: 500,
        fontSize: '1rem',
        color: '#404040'
      },
      fontFamily: ['Open Sans', 'sans-serif'].join(',')
    },
    components: {
      MuiSelect: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiOutlinedInput: {
        defaultProps: {
          margin: 'dense'
        },
        styleOverrides: {
          root: {
            '& fieldset': {
              borderRadius: '4px'
            },
            '&$focused $notchedOutline': {
              borderWidth: '1px'
            }
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: '0.5em 0'
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          color: 'primary',
          margin: 'none',
          variant: 'standard'
        },
        styleOverrides: {
          root: {
            fontSize: '0.8rem'
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '0.8rem'
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&$shrink': {
              fontSize: '0.8rem',
              fontWeight: 'bold',
              color: '#52657a',
              transform: 'translate(0, 1.5px) scale(0.8)'
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: '0.9rem'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            color: 'rgba(0, 0, 0, 1)'
          }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            '&$expanded': {
              maxHeight: '32px',
              minHeight: '32px'
            }
          }
        }
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          color: 'primary',
          variant: 'contained'
        },
        styleOverrides: {
          root: {
            minWidth: '86px'
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '1em'
          }
        }
      },
      MuiToolbar: {
        defaultProps: {
          variant: 'dense'
        },
        styleOverrides: {
          dense: {
            minHeight: '24px'
          }
        }
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'primary',
          size: 'small'
        }
      }
    }
  });
};

export default LogMaxTheme();
