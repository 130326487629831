import useCookieSettings from '@zert-packages/components/common/Cookie/useCookieSettings';
import { useEffect, useState } from 'react';
import { GetEnv } from '@zert-packages/react/EnvProvider';
import ReactGA from 'react-ga4';
import isDev from '@zert-packages/utils/isDev';
import { useLocation } from 'react-router-dom';

const useGoogleAnalytics = () => {
  const { cookieSettings } = useCookieSettings();
  const [initialized, setInitialized] = useState(false);

  const location = useLocation();
  const GoogleId = GetEnv('REACT_APP_GOOGLE_ANALYTICS');

  useEffect(() => {
    if (GoogleId) {
      ReactGA.initialize(`${GoogleId}`, { testMode: isDev() });
      setInitialized(true);
    } else if (!GoogleId) {
      console.warn('Google Analytics not initialized, GoogleId has not been set up properly.');
    }
  }, []);

  useEffect(() => {
    if (initialized && cookieSettings?.analytics) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search + location.hash });
    }
  }, [initialized, location, cookieSettings]);
};

export default useGoogleAnalytics;
