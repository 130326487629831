import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useCookie from '@zert-packages/components/common/Cookie/useCookie';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from './InfoElementWrapper/styles';
import VariablesPane from './VariablesPane';
import CommentsPane from './CommentsPane';
import StructurePane from './Structure/StructurePane';
import InfoExpander from './InfoExpander';
import FiltersPane from './LanguageFilters/FiltersPane';
import MetadataPane from './Metadata/MetadataPane';
import { PanelContext } from './InfoElementWrapper/InfoElementWrapper';
import MultipleItems from './MultipleItems';
import ElementPropertiesPane from './ElementPropertiesPane';

function SidePanel({ context = undefined, style = {}, multiSelect = false, title, direction, id, panels }) {
  const cl = useStyles();

  const { values } = useContext(context || PanelContext);

  const [expandedPane, setExpandedPane, removeExpandedPane] = useCookie(
    id,
    {
      panel: true,
      properties: true,
      filters: true,
      metadata: true,
      structure: true,
      variables: true,
      comments: true
    },
    365
  );

  return (
    <div className={cl.rootSidePanel} style={{ minWidth: expandedPane.panel ? '25px' : '300px', maxWidth: '300px' }}>
      {expandedPane.panel ? (
        <div
          onClick={() => setExpandedPane({ ...expandedPane, panel: !expandedPane.panel })}
          className={cl.wrapperArrowLeftExpanded}
        >
          <ArrowDropDownIcon className={direction === 'left' ? cl.arrowLeft : cl.arrowRight} />
          <div className={direction === 'left' ? cl.wrapperArrowText : cl.wrapperArrowTextRight}>{title}</div>
        </div>
      ) : (
        <div className={cl.wrapperArrowLeft}>
          <div>{title}</div>
          <ArrowDropDownIcon
            onClick={() => setExpandedPane({ ...expandedPane, panel: !expandedPane.panel })}
            className={direction === 'left' ? cl.arrowLeft : cl.arrowRight}
            name={direction}
          />
        </div>
      )}

      {multiSelect && values.severalSelectedTreeItem?.length > 1 ? (
        <MultipleItems />
      ) : (
        <div style={{ display: expandedPane.panel ? 'none' : 'flex', flexDirection: 'column' }}>
          {panels.includes('properties') && (
            <InfoExpander
              expanded={expandedPane}
              setExpanded={setExpandedPane}
              id="properties"
              title={<FormattedMessage id="ElementProperties" defaultMessage="Element Properties" />}
            >
              <ElementPropertiesPane context={context} />
            </InfoExpander>
          )}
          {panels.includes('filters') && (
            <InfoExpander
              expanded={expandedPane}
              setExpanded={setExpandedPane}
              id="filters"
              title={<FormattedMessage id="Filters" defaultMessage="Filters" />}
            >
              <FiltersPane />
            </InfoExpander>
          )}
          {panels.includes('metadata') && (
            <InfoExpander
              expanded={expandedPane}
              setExpanded={setExpandedPane}
              id="metadata"
              title={<FormattedMessage id="Metadata" defaultMessage="Metadata" />}
            >
              <MetadataPane context={context} />
            </InfoExpander>
          )}

          {panels.includes('structure') && (
            <InfoExpander
              expanded={expandedPane}
              setExpanded={setExpandedPane}
              id="structure"
              title={<FormattedMessage id="Structure" defaultMessage="Structure" />}
            >
              <StructurePane context={context} />
            </InfoExpander>
          )}
          {panels.includes('variables') && (
            <InfoExpander
              expanded={expandedPane}
              setExpanded={setExpandedPane}
              id="variables"
              title={<FormattedMessage id="Variables" defaultMessage="Variables" />}
            >
              <VariablesPane />
            </InfoExpander>
          )}
          {panels.includes('comments') && (
            <InfoExpander
              expanded={expandedPane}
              setExpanded={setExpandedPane}
              id="comments"
              title={<FormattedMessage id="Comments" defaultMessage="Comments" />}
            >
              <CommentsPane />
            </InfoExpander>
          )}
        </div>
      )}
    </div>
  );
}

export default SidePanel;
