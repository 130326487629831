import { Stack } from '@mui/material';
import CheckboxWithMemo from '@zert-packages/components/common/CheckboxWithMemo';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

function CookieSettings({ cookies, setCookies, setCookie }) {
  useEffect(() => {
    if (!cookies) {
      setCookies({ userExperience: true, analytics: true });
    }
  }, [cookies]);

  if (!cookies) {
    return <Loader />;
  }

  return (
    <Stack spacing={1} useFlexGap>
      <CheckboxWithMemo
        disabled
        isChecked
        label={
          <>
            <div style={{ fontWeight: 'bold' }}>
              <FormattedMessage id="settings.caption.functional" defaultMessage="Functional" />
            </div>
            <span>
              <FormattedMessage
                id="settings.caption.functionalDesc"
                defaultMessage="These cookies are strictly necessary to enable our website to operate and to keep it secure for our users."
              />
            </span>
          </>
        }
      />
      <CheckboxWithMemo
        isChecked={cookies.userExperience}
        label={
          <>
            <div style={{ fontWeight: 'bold' }}>
              <FormattedMessage id="settings.caption.ux" defaultMessage="User Experience" />
            </div>
            <span>
              <FormattedMessage
                id="settings.caption.uxDesc"
                defaultMessage="These cookies stores your states and configurations that you previously have made, for example minimizing and expanding certain views."
              />
            </span>
          </>
        }
        onChange={() => setCookie('userExperience')}
      />
      <CheckboxWithMemo
        isChecked={cookies.analytics}
        label={
          <>
            <div style={{ fontWeight: 'bold' }}>
              <FormattedMessage id="settings.caption.analytics" defaultMessage="Analytics" />
            </div>
            <span>
              <FormattedMessage
                id="settings.caption.analyticsDesc"
                defaultMessage="These cookies help collect data that informs us (anonymously) how customers use our site and provides information to help us improve the website and your browsing experience, without personally identifying individual visitors."
              />
            </span>
          </>
        }
        onChange={() => setCookie('analytics')}
      />
    </Stack>
  );
}

export default CookieSettings;
