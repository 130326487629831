import CookieSettings from '@zert-packages/components/common/Cookie/CookieSettings';
import useCookieSettings from '@zert-packages/components/common/Cookie/useCookieSettings';
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function CookieSettingsDialog({}) {
  const { cookieSettings, setCookieSettings, setCookieSetting, saveCookieSettings } = useCookieSettings();
  const [open, setOpen] = React.useState(true);

  const withUnmountComponent = (callback) => () => {
    setOpen(false);
    if (callback) callback();
  };
  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessage id="CookiesDialog.Title" defaultMessage="We value your privacy" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            id="CookeisDialog.Desc"
            defaultMessage="We use cookieSettings to help give you the best experience on our site and to analyse how the site is used."
          />
        </DialogContentText>
        <CookieSettings cookies={cookieSettings} setCookies={setCookieSettings} setCookie={setCookieSetting} />
      </DialogContent>
      <DialogActions>
        <Button onClick={withUnmountComponent(saveCookieSettings)} color="primary" variant="outlined">
          <FormattedMessage id="CookiesDialog.Save" defaultMessage="Save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CookieSettingsDialog;
