import { FormattedMessage } from 'react-intl';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import {
  storePermissions,
  storeTemplates,
  loadTemplates,
  loadGroupsList,
  loadPermissions
} from '@zert-packages/actions/coreReducers';
import SaveActions from '@zert-packages/plugins/MailSettingsPlugin/common/CancelSavePanel';
import { useDispatch, useSelector } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import { formStyle } from '@zert-packages/components/shared/FormElements/StylesHelper';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import MultiSelect from '@zert-packages/components/common/MultiSelect';
import { modules, wft } from '@zert-packages/react/adminSettings';
import { useHistory } from 'react-router-dom';
import { SettingsContext } from './ContextProvider';
import TemplateEditor from '../../common/TemplateEditor';
import ModuleEditor from '../../common/ModuleEditor';

const useStyles = makeStyles(formStyle);
const modulesAdapter = ({ label, name, valueTr, ...other }, intl) => ({
  ...other,
  name,
  label: intl.formatMessage({ id: name.props.id })
});
export const PermissionTab = forwardRef(({ companyId, intl, backFunction }, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modulesSelected, setModulesSelected] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const isAdmin = useSelector((core) => core.myuser?.role === 'administrator');
  const { permissions, groupslist, companyTemplates } = useSelector((state) => state);
  useImperativeHandle(ref, () => ({
    store() {
      const templatesToStore = [];
      const keys = Object.keys(companyTemplates);
      keys.map((key) => {
        companyTemplates[key].map((template) => {
          templatesToStore.push({
            elementId: template.id,
            active: !template.properties.disabled,
            default: template.properties.default != null,
            elementUUID: template.elementUUID,
            mimeType: template.mimeType
          });
        });
      });
      setStoreCompany(true);

      async function storeCompanyAsync() {
        await storeTemplates(companyId, templatesToStore);
        storeSelected();
        await storePermissions(companyId, permissions.permissions);
        setStoreCompany(false);
        setIsSomethingChanged(false);
      }

      storeCompanyAsync();
    }
  }));

  useEffect(() => {
    if (!modulesSelected && permissions && permissions.companyid === companyId) {
      setModulesSelected(filterSelected(permissions));
      setLoading(false);
    }
    if (!loading && !modulesSelected && (!permissions || permissions.companyid !== companyId)) {
      setLoading(true);
      dispatch(loadTemplates(companyId, true));
      dispatch(loadGroupsList(companyId, isAdmin));
      dispatch(loadPermissions(companyId));
    }
  }, [permissions]);

  const filterSelected = (permissions) => {
    const modulesSelected = [];
    permissions.permissions.map((permission) => {
      const module = modules.find((module) => module.value === permission.pluginName);
      if (module) {
        modulesSelected.push(modulesAdapter(module, intl));
      }
    });
    return modulesSelected;
  };

  const storeSelected = () => {
    const permissionsSelected = [];
    permissions.permissions.map((permission) => {
      const module = modulesSelected.find((module) => module.value === permission.pluginName);
      if (module) {
        permissionsSelected.push(permission);
      }
    });
    return (permissions.permissions = permissionsSelected);
  };

  const { handleSave, handleCancel, errors, setIsSomethingChanged, setStoreCompany } = useContext(SettingsContext);

  const handleChange = (event) => {
    setIsSomethingChanged(true);
  };
  const handleMultiSelectChange = (newValue) => {
    setModulesSelected(newValue);
    setIsSomethingChanged(true);
    // storeSelected(newValue);
  };

  const filterPermisission = (moduleSelected, permissions) => {
    if (!permissions) {
      return { permits: [] };
    }
    let permit = permissions.permissions.find((permission) => moduleSelected.value === permission.pluginName);
    if (!permit) {
      permit = {
        pluginName: moduleSelected.value,
        permits: []
      };
      permissions.permissions.push(permit);
    }
    return permit;
  };

  const clickSave = (e) => {
    handleSave();
    backFunction();
  };

  const clickCancel = (e) => {
    handleCancel();
    backFunction();
  };

  if (!modulesSelected || !companyTemplates || !groupslist) {
    return <Loader />;
  }

  const modules2 = modules.map((module) => modulesAdapter(module, intl));
  // debugger;
  return (
    <div className="question-block">
      <h3>
        <FormattedMessage id="settings.companyeditor.permissions" defaultMessage="Permissions" />
      </h3>

      {/*    <MultiSelectField
      fieldId="templates"
      isMulti={true}
      value={modulesSelected}
      label={intl.formatMessage({
        id: 'settings.companyeditor.machines',
        defaultMessage: 'Modules',
      })}
      placeholder={intl.formatMessage({
        id: 'settings.companyeditor.placeholder.modules',
        defaultMessage: 'Select modules',
      })}
      onStateChanged={handleMultiSelectChange}
      options={modules}
    /> */}

      <MultiSelect
        value={modulesSelected || []}
        label={<FormattedMessage id="settings.companyeditor.modules" defaultMessage="Modules" />}
        onChange={handleMultiSelectChange}
        options={modules2}
      />

      {modulesSelected &&
        groupslist &&
        modulesSelected.map((module) => {
          return (
            <ModuleEditor
              handleChange={handleChange}
              groupslist={groupslist}
              module={module}
              permits={filterPermisission(module, permissions)}
              intl={intl}
            />
          );
        })}

      <h3>
        <FormattedMessage id="settings.companyeditor.templates" defaultMessage="Templates" />
      </h3>
      <TemplateEditor
        handleChange={handleChange}
        module={wft}
        history={history}
        companyId={companyId}
        templates={companyTemplates[wft.templateMimeType]}
        intl={intl}
      />

      {modulesSelected &&
        modulesSelected.map((module) => {
          if (module.hasTemplates) {
            return (
              <TemplateEditor
                handleChange={handleChange}
                history={history}
                classes={classes}
                companyId={companyId}
                templates={companyTemplates[module.templateMimeType]}
                module={module}
                intl={intl}
              />
            );
          }
        })}
      <SaveActions onSave={clickSave} onCancel={clickCancel} />
    </div>
  );
});
