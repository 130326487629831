import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  stickyStyle: (props) => ({
    position: 'sticky',
    top: `${props.top}px`,
    zIndex: props.zIndex,
    ...props.appendStickyStyle
  }),
  unStickyStyle: (props) => ({
    ...props.appendUnStickyStyle
  })
}));

export const calcStickyParent = (ref, identifier) => {
  let cumulativeHeight = 0;
  let cumulativeZindex = 100;

  const element = ref.current;
  if (!element) {
    return { cumulativeHeight, cumulativeZindex };
  }

  const parentElements = document.querySelectorAll(`.${identifier}`);

  parentElements.forEach((parent) => {
    if (parent !== element && parent.contains(element)) {
      cumulativeHeight += parent.firstChild.offsetHeight;
      cumulativeZindex--;
    }
  });

  return { cumulativeHeight, cumulativeZindex };

  return { cumulativeHeight, cumulativeZindex };
};

function HierarchicalStickyHeader({ stickyCondition, identifier, appendStickyStyle, appendUnStickyStyle, children }) {
  const ref = useRef();
  const [top, setTop] = useState(0);
  const [zIndex, setZindex] = useState(100);
  const cl = useStyles({ appendStickyStyle, appendUnStickyStyle, top, zIndex });

  useEffect(() => {
    const updateStickyPosition = () => {
      const { cumulativeHeight, cumulativeZindex } = calcStickyParent(ref, identifier);
      setTop(cumulativeHeight);
      setZindex(cumulativeZindex);
    };

    updateStickyPosition();
    window.addEventListener('resize', updateStickyPosition);
    return () => window.removeEventListener('resize', updateStickyPosition);
  }, [stickyCondition, identifier]);

  return (
    <div ref={ref} className={`${stickyCondition ? `${cl.stickyStyle}` : cl.unStickyStyle}`}>
      {children}
    </div>
  );
}

export default HierarchicalStickyHeader;
