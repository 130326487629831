import {handleBlock} from "@zert-packages/actions/commonReducer";

const undoPhrase = (type, dispatch) => {
    dispatch(handleBlock(type, 'phraseEditorActionType'))
}


const undoAction = (e, dispatch, typeSelectedBlock) => {
    if (typeSelectedBlock === 'zert-phrase') {
        return undoPhrase('undo', dispatch)
    }

}

export default undoAction