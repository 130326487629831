import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCookieSettings, storeCookieSettings } from './CookieSettingsAPI';

const useCookieSettings = () => {
  const cookieSettings = useSelector((state) => state.COOKIESETTINGS.cookieSettings);
  const isCookieSettingsLoading = useSelector((state) => state.COOKIESETTINGS.isCookieSettingLoading);
  const isCookieSettingsLoaded = useSelector((state) => state.COOKIESETTINGS.isCookieSettingsLoaded);
  const [localCookieSettings, setLocalCookieSettings] = useState(cookieSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cookieSettings && !isCookieSettingsLoading) {
      dispatch(getCookieSettings());
    }
  }, [cookieSettings, isCookieSettingsLoading]);

  useEffect(() => {
    if (cookieSettings && cookieSettings !== localCookieSettings) {
      setLocalCookieSettings(cookieSettings);
    }
  }, [cookieSettings]);

  const setCookieSettings = (cookies) => {
    setLocalCookieSettings(cookies);
  };

  const setCookieSetting = (key) => {
    setLocalCookieSettings((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const saveCookieSettings = useCallback(() => {
    dispatch(storeCookieSettings(localCookieSettings));
  }, [localCookieSettings]);

  return {
    cookieSettings: localCookieSettings,
    isCookieSettingsChanged: cookieSettings && localCookieSettings && cookieSettings !== localCookieSettings,
    isCookieSettingsLoading,
    isCookieSettingsLoaded,
    setCookieSettings,
    setCookieSetting,
    saveCookieSettings
  };
};

export default useCookieSettings;
