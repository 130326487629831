import BlockIcon from '@zert-packages/muiadopticons/BlockIcon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { getPlugins } from '@zert-packages/actions/api';
import { findElements, placeElementNew } from '@zert-packages/actions/catalogActions';
import { addElementsToFolderApi } from '@zert-packages/plugins/ExplorerPlugin/API';
import { getChildNodes, getNodeContext } from '@zert-packages/plugins/ExplorerPlugin/servicesMidleware';
import { getMultipleValueOneCatalogSelected } from '@zert-packages/utils/getMultipleValue';
import hasPermit from '@zert-packages/utils/hasPermit';

import { getPluginTranslatableName } from '@zert-packages/utils/getPluginByMimeType';

import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

import dialogCustom from './common/dialogCustom';

function CreateNewSelectionDropdown({ catalogNode, catalogIndex, label, icon, size }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const [{ catalog }, setCatalog] = useState({ catalog: null });
  const { permits, catalog: catalogList } = useSelector((state) => state);
  const { selectedNode } = useSelector((state) => state.CORE);
  const dispatch = useDispatch();

  useEffect(() => {
    if (catalogList) {
      setCatalog((prevState) => ({ catalog: catalogList[catalogIndex] }));
    }
  }, [catalogList, catalogIndex]);

  const plugins = getPlugins()
    .filter((plugin) => plugin.isCreateNew)
    .filter((plugin) => plugin.routes)
    .filter((plugin) => {
      if (!plugin.createNewDialog) return false;
      return hasPermit(plugin, permits);
    });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const onCreate = (plugin) => {
    handleClose();
    dialogCustom(plugin.createNewDialog, {
      onCreate: async (res) => {
        if (catalog === null || catalog === undefined) {
          await addElementsToFolderApi(selectedNode.id, [res.versionId]);
          dispatch(getNodeContext(selectedNode, true));
          dispatch(getChildNodes(selectedNode.id, true));
        } else {
          await placeElementNew(res.versionId, getMultipleValueOneCatalogSelected(catalog, catalogNode));
          dispatch(findElements(catalogNode.split('^')[1], catalog.info.id));
        }
      }
    });
  };

  const sortedPlugins = plugins.sort((a, b) =>
    getPluginTranslatableName(intl, a).localeCompare(getPluginTranslatableName(intl, b))
  );

  const menuitems = sortedPlugins.map((plugin) => {
    return (
      <MenuItem key={`MenuItem-${plugin.mimeType}`} onClick={(e) => onCreate(plugin)}>
        <ListItemIcon>
          <BlockIcon svg={plugin.icon} />
        </ListItemIcon>
        <ListItemText primary={getPluginTranslatableName(intl, plugin)} />
      </MenuItem>
    );
  });

  return (
    <>
      <Button
        id="basic-button-create-new"
        aria-controls={open ? 'create-new-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={icon}
        size={size}
      >
        {label}
      </Button>
      <Menu
        id="create-new-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button-create-new'
        }}
      >
        {menuitems}
      </Menu>
    </>
  );
}

export default CreateNewSelectionDropdown;
