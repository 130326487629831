import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import ArrowToggleButton, { ARROW_TOGGLE_VARIANTS } from '../../shared/ArrowToggleButton';
import NotificationSquareNumber from '../../shared/NotificationSquareNumber';
import UserNameLabel from './UserNameLabel';
import ListCore from './ListCore';
import { BACKGROUND_LINEAR_GRADIENT } from '../SIDE_BAR_CONTS';

const useStyles = makeStyles({
  rootWrapper: {
    position: 'relative'
  },
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: '30px 15px 1fr 50px',
    alignItems: 'center',
    borderBottom: 'solid 1px rgba(27, 161, 197, .05)',
    cursor: 'pointer',
    height: '100%',
    padding: '0px 10px 0px 20px'
  },
  badgeContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  additionalListContainer: {
    transition: 'all 0.5s ease',
    position: 'absolute',
    width: '100%',
    bottom: '0',
    transform: 'scaleX(0)',
    transformOrigin: '0% 100%',
    background: BACKGROUND_LINEAR_GRADIENT
  },
  additionalListContainerOpen: {
    transform: 'scaleX(1)'
  }
});

function AdditionalList({ user, onlyIcons, list, number, onClick }) {
  const cl = useStyles();

  const additionalListClassName = cn({
    [cl.additionalListContainer]: true,
    [cl.additionalListContainerOpen]: !onlyIcons
  });

  return (
    <div className={additionalListClassName}>
      {!onlyIcons && (
        <div data-intro-id="SideBar.Settings" className={cl.rootWrapper}>
          <ListCore list={list} onClick={onClick}/>
        </div>
      )}
    </div>
  );
}

export default AdditionalList;
