import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@mui/styles';
import { useHeightOffset } from '@zert-packages/react/hooks/useHeightOffset';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 auto',
    width: '100%',
    overflow: 'hidden',
    padding: '0.5em 0em',
    zIndex: 1,
    minHeight: '50px',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  loader: {
    display: 'inline-block',
    position: 'relative',
    width: '50px',
    height: '50px',
    verticalAlign: 'middle'
  },
  loaderQuart: {
    borderRadius: '50px',
    border: '6px solid rgba(204, 208, 210, 0.4)',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '-6px',
      right: '-6px',
      bottom: '-6px',
      left: '-6px',
      borderRadius: '50px',
      border: '6px solid transparent',
      borderTopColor: '#188ec7',
      WebkitAnimation: 'spin 1.5s linear infinite',
      animation: 'spin 1.5s linear infinite'
    }
  },
  loaderMessage: {
    marginLeft: '1em',
    marginRight: '4px',
    whiteSpace: 'break-spaces'
  }
});

type LoaderType = {
  children?: JSX.Element;
  offsetHeight?: number;
  displayText?: boolean;
  loaderAction?: LoaderAction;
};

export enum LoaderAction {
  None,
  Loading,
  Storing,
  Verifying,
  Approving,
  Creating,
  Updating,
  Performing
}

export function Loader({
  children,
  offsetHeight,
  displayText = true,
  loaderAction = LoaderAction.Loading
}: LoaderType) {
  const [isWaiting, setIsWaiting] = useState(true);

  const loaderRef = useRef<HTMLDivElement>(null);
  const heightOffset = useHeightOffset(loaderRef.current);

  const waitBeforeShow = 250;
  const cl = useStyles();

  const getLoaderAction = () => {
    if (loaderAction === LoaderAction.None) {
      return '';
    }
    if (loaderAction === LoaderAction.Loading) {
      return <FormattedMessage id="loader.loading.message" defaultMessage="Loading" />;
    }
    if (loaderAction === LoaderAction.Storing) {
      return <FormattedMessage id="loader.storing.message" defaultMessage="Storing" />;
    }
    if (loaderAction === LoaderAction.Verifying) {
      return <FormattedMessage id="loader.verifying.message" defaultMessage="Verifying" />;
    }
    if (loaderAction === LoaderAction.Approving) {
      return <FormattedMessage id="loader.approving.message" defaultMessage="Approving" />;
    }
    if (loaderAction === LoaderAction.Creating) {
      return <FormattedMessage id="loader.creating.message" defaultMessage="Creating" />;
    }
    if (loaderAction === LoaderAction.Updating) {
      return <FormattedMessage id="loader.updating.message" defaultMessage="Updating" />;
    }
    if (loaderAction === LoaderAction.Performing) {
      return <FormattedMessage id="loader.performing.message" defaultMessage="Performing" />;
    }

    return <FormattedMessage id="loader.loading.message" defaultMessage="Loading" />;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loaderRef.current) {
        setIsWaiting(false);
      }
    }, waitBeforeShow);
    return () => clearTimeout();
  }, [loaderRef.current]);

  return (
    <>
      <div ref={loaderRef} id="loader-indicator" />
      {(heightOffset !== null || offsetHeight !== null) && !isWaiting && (
        <div
          className={cl.root}
          style={{
            height:
              offsetHeight || offsetHeight === 0
                ? `calc(100% - ${offsetHeight}px)`
                : heightOffset || heightOffset == 0
                ? `calc(100% - ${heightOffset}px)`
                : ''
          }}
        >
          <div className={`${cl.loader} ${cl.loaderQuart}`} />
          {displayText && (
            <span>
              <div className={cl.loaderMessage}>{getLoaderAction()}</div>
              {children && <div style={{ textTransform: loaderAction ? 'lowercase' : 'unset' }}>{children}</div>}
              ...
            </span>
          )}
        </div>
      )}
    </>
  );
}

export default Loader;
