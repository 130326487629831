import React, {useState} from 'react'
import {Menu} from "@mui/material";
import {useSelector} from "react-redux";
import BlockIcon from "@zert-packages/muiadopticons/BlockIcon";
import {getPluginByMimeTypeIcon} from "@zert-packages/utils/getPluginByMimeType";
import makeStyles from "@mui/styles/makeStyles";
import HistoryIcon from '@mui/icons-material/History';


const useStyles = makeStyles((theme) => ({
    menuRoot: {
        '& .MuiList-root': {
            minWidth: 120,
            padding: 5,
            minHeight: 114,
        },
    },
    title: {
        borderBottom: '1px solid rgba(145, 145, 145, 0.25)',
        display: 'flex',
        gap: 5,
        marginBottom: 5,
    },
    list: {
        display: 'flex',
        gap: 5,
        padding: '2px 4px',
        cursor: 'pointer',
    },
    activeList: {
        cursor: 'default',
        background: '#4db1d3',
        color: '#ffffff',
    }
}))

const test = [{name: 'test'}, {name: 'tes2'}]

const HistoryStepper = ({anchorEl, type, close}) => {
    const cl = useStyles()
    const {block} = useSelector((state) => state);
    const selectedItem = block?.selectedItem
    const [historyStepper, setHistoryStepper] = useState({
        selected: -1,
    })

    const handleClose = () => {
        if (close) {
            close(type)
        }
    }
    const handleSelectedItem = (i) => {
        setHistoryStepper(pre => ({...pre, selected: i}))
    }

    console.log('block', block)

    return (<Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={cl.menuRoot}
    >
        <div>
            <div className={cl.title}>
                <BlockIcon svg={getPluginByMimeTypeIcon(selectedItem.elementInfo.mimeType, true)}/>
                {selectedItem.elementInfo.name}
            </div>

            {test.map((el, i) => <div
                key={i}
                className={`${cl.list} ${historyStepper.selected === i ? cl.activeList : ''}`}
                onClick={() => handleSelectedItem(i)}
            >
                <HistoryIcon/>
                <span>{el.name}</span>
            </div>)}

        </div>
    </Menu>)
}

export default HistoryStepper