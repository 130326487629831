import React, { useCallback, useContext, useEffect, useState } from 'react';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import SkeletonLoader from '@zert-packages/components/SkeletonLoader';
import { PanelContext } from '@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper';
import { useRoveFocus } from '@zert-packages/utils';
import addId from '@zert-packages/utils/addId';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { useParams } from 'react-router-dom';
import { handleBlock } from '@zert-packages/actions/commonReducer';
import useWebSocket from './utils/useWebSocket';
import BlockItems from './BlockItems';
import { deleteBlockItems, findSelectedText, handleStepperKey, handleTreeItem, handleValue } from './utils/main';
import { getBlockApi } from './API';
import { defaultSelectedRangeData, defaultValueData } from './data/DataBlockWrapper';
import { copyMainParent, pasteMainParent } from './utils/pasteMainParent';
import AlertTextDialog from './components/dialog/AlertTextDialog';

import { findIndexCreateBlock } from './utils/findIndex';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 5
  }
}));

function BlockWrapper({ block, table, idBlock }) {
  const cl = useStyles();
  const { formatTemplate, versionInfo, myLanguage, actionHeader } = useSelector((state) => state);
  const { versionId: paramVersionId } = useParams();
  const dispatch = useDispatch();
  const versionId = idBlock || paramVersionId;
  const { values, setValues } = useContext(PanelContext);
  const [valuesBlock, setValueBlock] = useState(null);
  const [selectedRange, setSelectedRange] = useState(defaultSelectedRangeData);
  const indexFocus = values.treeItem ? selectedRange.lastSelectedIndex : -1;
  const [looksState, setLooksState] = useState(false);
  const blockRedactor = !values.blockKey && !values.blockKeyRedactor;
  const [itemSelectedBlock, setItemSelectedBlock] = useState(null);
  const { copyKey, insert, deleteKey, currentFocus, left, right, ctrlZ, setKey } = useRoveFocus(
    valuesBlock?.items?.length,
    false,
    indexFocus,
    setValues,
    setLooksState,
    !!values.blockKeyRedactor,
    true
  );
  const { socket, setSocket } = useWebSocket();
  const indexCreateElement = findIndexCreateBlock(valuesBlock?.items);

  const getBlock = useCallback(
    async (lng) => {
      if (versionId && lng) {
        const res = block || (await getBlockApi(versionId, lng.value));
        if (res) {
          const adobeRes = { ...res, identifier: '0', items: addId(res.items) };
          const parentItemChild = { ...adobeRes, parentTreeItem: true };
          setValueBlock(adobeRes);
          setSelectedRange(defaultSelectedRangeData);
          handleValue(false, false, setValues, {
            ...defaultValueData,
            treeItem: parentItemChild,
            parentItemChild
          });
          setSocket([]);
        }
      }
    },
    [versionId]
  );

  const getChildSelectedBlock = async (it) => {
    if (!it) {
      return;
    }
    const info = it.elementInfo;
    if (info.mimeType.split('/').pop() !== 'zert-block') {
      return;
    }
    handleValue(false, false, setSelectedRange, {
      loaderBlockItems: true
    });
    const result = info && (await getBlockApi(info.versionId, myLanguage.value));
    if (result?.items?.length > 0) {
      handleValue(false, false, setSelectedRange, {
        selectedBlockChild: { ...result, identifier: it.identifier },
        loaderBlockItems: false
      });
      setValues((pre) => ({
        ...pre,
        investmentLevel: pre.investmentLevel + 1,
        treeItem: result.items[0]
      }));
      return;
    }
    handleValue(false, false, setSelectedRange, {
      loaderBlockItems: false,
      selectedBlockChild: null
    });
  };

  const handleSelectedBlock = (itemSelectedBlock, currentFocus, copyBlock, valuesProps = {}) => {
    handleValue(false, false, setSelectedRange, {
      lastSelectedIndex: currentFocus,
      blockParentSelectedLvl: false
    });
    const valuesState = {
      treeItem: itemSelectedBlock,
      closeChild: itemSelectedBlock.identifier,
      dropBlockTable: false,
      ...valuesProps
    };
    if (copyBlock) {
      return setValues((pre) => ({
        ...pre,
        ...valuesState,
        copyBlock: { ...pre.copyBlock, ...copyBlock }
      }));
    }
    handleValue(false, false, setValues, valuesState);
  };
  useEffect(() => {
    if (right && !values.blockKeyRedactor) {
      handleValue(false, 'closeChild', setValues);
      getChildSelectedBlock(itemSelectedBlock);
    }
  }, [right, values.blockKey, values.blockKeyRedactor, looksState]);

  useEffect(() => {
    if (currentFocus >= 0 && blockRedactor && itemSelectedBlock) {
      handleSelectedBlock(itemSelectedBlock, currentFocus);
    }
  }, [currentFocus, values.blockKey, values.blockKeyRedactor, itemSelectedBlock]);

  useEffect(() => {
    if (currentFocus >= 0 && valuesBlock?.items && !selectedRange.parentSelectedLvl) {
      setItemSelectedBlock(valuesBlock?.items[currentFocus]);
    }
  }, [currentFocus, valuesBlock?.items, selectedRange.parentSelectedLvl]);

  useEffect(() => {
    if (selectedRange.parentSelectedLvl?.child && !selectedRange.blockParentSelectedLvl) {
      setItemSelectedBlock(selectedRange.parentSelectedLvl.child.items[currentFocus]);
    }
  }, [selectedRange.parentSelectedLvl, currentFocus, selectedRange.blockParentSelectedLvl]);

  useEffect(() => {
    if (deleteKey && values.treeItem && !values.blockKeyRedactor) {
      if (values.investmentLevel === 1 || values.investmentLevel === 0) {
        setValueBlock((pre) => ({
          ...pre,
          items: pre.items.filter((el) => el.identifier !== values.treeItem.identifier)
        }));
      }
      deleteBlockItems(values.treeItem, setSelectedRange, values, setValues);
    }
  }, [deleteKey, looksState]);

  useEffect(() => {
    if (ctrlZ && values.stepperKey.length > 0) {
      handleStepperKey(
        values,
        setValues,
        valuesBlock,
        setValueBlock,
        handleSelectedBlock,
        insert,
        looksState,
        selectedRange,
        currentFocus,
        setSelectedRange
      );
    }
  }, [ctrlZ]);

  useEffect(() => {
    if (socket) {
      handleValue(socket, 'socket', setValues);
    }
  }, [socket]);

  useEffect(() => {
    if (values.copyMainParent && values.copyMainParent.actionType === 'copy') {
      copyMainParent(versionInfo, valuesBlock.items, setValues);
    }
  }, [values.copyMainParent]);

  useEffect(() => {
    if (values.pasteMainParent) {
      if (!values.copyMainParent) {
        return renderInExceptionRoot(AlertTextDialog, { text: 'There is nothing to paste, the clipboard is empty.' });
      }
      pasteMainParent(values.copyMainParent, valuesBlock.items, setValues, setValueBlock, setSelectedRange);
    }
  }, [values.pasteMainParent]);

  useEffect(() => {
    if (values?.searchNawBar?.searchText.length > 0 && valuesBlock?.items) {
      findSelectedText(valuesBlock?.items, values.searchNawBar.searchText, setValues);
      setValues((pre) => ({
        ...pre,
        action: {
          ...pre.action,
          saveElement: null,
          parentSaveElement: null
        }
      }));
    }
  }, [valuesBlock?.items, values?.searchNawBar?.searchText]);

  useEffect(() => {
    if (values.action?.parentItemCreate && values?.action?.parentItemCreate.identifier === '0') {
      const { type } = values.action.parentItemCreate;
      setValueBlock(values.action.parentItemCreate);
      if (type === 'save') {
        setKey((pre) => ({ ...pre, left: true }));
      }
    }
  }, [values?.action?.parentItemCreate]);

  useEffect(() => {
    if (values.addPublishingFilters) {
      handleTreeItem(values.addPublishingFilters, setValues, setValueBlock);
    }
  }, [values.addPublishingFilters]);

  useEffect(() => {
    getBlock(myLanguage);
  }, [getBlock, myLanguage]);

  useEffect(() => {
    if (values.treeItem) {
      dispatch(handleBlock(values.treeItem, 'selectedItem'));
    }
  }, [values.treeItem]);

  if (!formatTemplate) {
    return (
      <span style={{ marginTop: -5 }}>
        <Loader displayText={false} />
      </span>
    );
  }

  return (
    <div className={cl.root}>
      {valuesBlock?.items ? (
        valuesBlock?.items.map((el, i, arr) => {
          const isLastItem = i === arr.length - 1;
          return (
            <BlockItems
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
              key={el.identifier}
              index={i}
              isLastItem={isLastItem}
              parent={valuesBlock}
              parentChild={{ ...el, index: i, child: valuesBlock }}
              parentItemCreate={{ ...valuesBlock, versionId }}
              arrayParent={valuesBlock.items}
              el={el}
              investmentLevel={0}
              currentFocus={currentFocus}
              left={left}
              looksState={looksState}
            />
          );
        })
      ) : (
        <SkeletonLoader height={38} style={{ marginLeft: 5 }} />
      )}
      {valuesBlock?.items?.length === 0 && (
        <BlockItems
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          index={indexCreateElement}
          isLastItem={false}
          parent={valuesBlock}
          parentChild={{ ...valuesBlock, index: indexCreateElement, child: valuesBlock }}
          parentItemCreate={{ ...valuesBlock, versionId }}
          investmentLevel={0}
          currentFocus={currentFocus}
          left={left}
          looksState={looksState}
          createElement
          versionIdParent={versionId}
        />
      )}
    </div>
  );
}

export default BlockWrapper;
