import { FormattedMessage } from 'react-intl';
import React from 'react';
import {
  Archive,
  ContentCut,
  ContentPaste,
  ControlPointDuplicate,
  Create,
  Delete,
  FileCopy,
  Info,
  AttachFile,
  Preview,
  Print,
  Publish,
  Send,
  Unarchive,
  InsertDriveFile,
  Summarize,
  Settings as SettingsIcon
} from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

export const workflowIdKey = 'zert:CurrentWorkflowVersionId';
export const actionsKey = 'zert:WorkflowStagesIds';
export const actionsNamesKey = 'zert:WorkflowStagesNames';

export const TASK_ACTIONS_TYPES = {
  inform: 'inform',
  delegate: 'delegate',
  delete: 'delete',
  rename: 'rename',
  archive: 'archive',
  unarchive: 'unarchive',
  duplicate: 'duplicate',
  copy: 'copy',
  cut: 'cut',
  paste: 'paste',
  editSearchFolder: 'editSearchFolder',
  tableView: 'tableView',
  listView: 'listView',
  preview: 'preview',
  publish: 'publish',
  report: 'report',
  create_ped: 'create_ped',
  create_doc: 'create_doc',
  upload_doc: 'upload_doc',
  open: 'open',
  open_in_new: 'open_in_new',
  create_new: 'create_new'
};

export const CREATE_NEW = [
  {
    icon: <PlaylistAddIcon />,
    id: TASK_ACTIONS_TYPES.create_new,
    isDropdown: true,
    name: <FormattedMessage id="sidebar.creeatNewText" defaultMessage="Create new" />
  }
];

export const TASK_ACTIONS = [
  {
    icon: <Send />,
    id: TASK_ACTIONS_TYPES.delegate,
    name: <FormattedMessage id="ElementTile.Actions.Delegate" defaultMessage="Delegate" />
  }
];

export const ELEMENT_ACTIONS = [
  {
    icon: <FileCopy />,
    id: TASK_ACTIONS_TYPES.copy,
    name: <FormattedMessage id="ElementTile.Actions.Copy" defaultMessage="Copy" />
  }
];

export const OPEN_ACTION = {
  icon: <OpenInBrowserIcon />,
  id: TASK_ACTIONS_TYPES.open,
  name: <FormattedMessage id="ElementTile.Actions.Open" defaultMessage="Open" />
};

export const OPEN_IN_NEW_ACTION = {
  icon: <OpenInNewIcon />,
  id: TASK_ACTIONS_TYPES.open_in_new,
  name: <FormattedMessage id="ElementTile.Actions.Open" defaultMessage="Open" />
};

export const UPLOAD_DOC = [
  {
    icon: <AttachFile />,
    id: TASK_ACTIONS_TYPES.upload_doc,
    isUpload: true,
    name: <FormattedMessage id="ElementTile.Actions.UPLOAD_DOC" defaultMessage="UPLOAD DOC" />
  }
];

export const CREATE_DOC = [
  {
    icon: <InsertDriveFile />,
    id: TASK_ACTIONS_TYPES.create_doc,
    name: <FormattedMessage id="ElementTile.Actions.CREATE_LINK" defaultMessage="Create link" />
  }
];

export const REPORT_ACTIONS = [
  {
    icon: <Print />,
    id: TASK_ACTIONS_TYPES.report,
    name: <FormattedMessage id="ElementTile.Actions.Report" defaultMessage="Report" />
  }
];

export const PREVIEW_ACTIONS = [
  {
    icon: <Preview />,
    id: TASK_ACTIONS_TYPES.preview,
    name: <FormattedMessage id="ElementTile.Actions.Preview" defaultMessage="Preview" />
  }
];

export const PUBLISH_ACTIONS = [
  {
    icon: <Publish />,
    id: TASK_ACTIONS_TYPES.publish,
    name: <FormattedMessage id="ElementTile.Actions.Publish" defaultMessage="Publish" />
  }
];
export const CUT_ACTION = {
  icon: <ContentCut />,
  id: TASK_ACTIONS_TYPES.cut,
  name: <FormattedMessage id="ElementTile.Actions.Cut" defaultMessage="Cut" />
};

export const PASTE_ACTION = {
  icon: <ContentPaste />,
  id: TASK_ACTIONS_TYPES.paste,
  name: <FormattedMessage id="ElementTile.Actions.Paste" defaultMessage="Paste" />,
  showAlways: true
};

export const DELETE_ACTION = {
  id: TASK_ACTIONS_TYPES.delete,
  icon: <Delete />,
  name: <FormattedMessage id="ElementTile.Actions.Delete" defaultMessage="Delete" />,
  isArchived: false,
  isApproved: false,
  hasVersions: false
};
export const ARCHIVE_ACTION = {
  icon: <Archive />,
  id: TASK_ACTIONS_TYPES.archive,
  name: <FormattedMessage id="ElementTile.Actions.Archive" defaultMessage="Archive" />,
  isArchived: false
};
export const UNARCHIVE_ACTION = {
  icon: <Unarchive />,
  id: TASK_ACTIONS_TYPES.unarchive,
  name: <FormattedMessage id="ElementTile.Actions.Unarchive" defaultMessage="Unarchive" />,
  isArchived: true
};

export const INFORM_ACTIONS = [
  {
    icon: <Info />,
    id: TASK_ACTIONS_TYPES.inform,
    name: <FormattedMessage id="ElementTile.Actions.Inform" defaultMessage="Inform" />
  }
];

export const OVERVIEW_ACTIONS = [
  {
    icon: <Create />,
    id: TASK_ACTIONS_TYPES.rename,
    name: <FormattedMessage id="ElementTile.Actions.Rename" defaultMessage="Rename" />,
    isArchived: false,
    isApproved: false
  }
];
export const DUPLICATE_ACTION = [
  {
    icon: <ControlPointDuplicate />,
    id: TASK_ACTIONS_TYPES.duplicate,
    name: <FormattedMessage id="ElementTile.Actions.Duplicate" defaultMessage="Duplicate" />
  }
];

export const EDIT_SEARCH_FOLDER_ACTION = [
  {
    icon: <SettingsIcon />,
    id: TASK_ACTIONS_TYPES.editSearchFolder,
    name: <FormattedMessage id="ElementTile.Actions.editSearchFolder" defaultMessage="Edit Search Folder" />
  }
];
