import { createAction, createReducer } from '@reduxjs/toolkit';

export const COOKIE_SETTINGS_FETCH = createAction('COOKIE_SETTINGS_FETCH');
export const COOKIE_SETTINGS_FETCH_DONE = createAction('COOKIE_SETTINGS_FETCH_DONE');
export const COOKIE_SETTINGS_STORE_START = createAction('COOKIE_SETTINGS_STORE_START');
export const COOKIE_SETTINGS_STORE_DONE = createAction('COOKIE_SETTINGS_STORE_DONE');

export const cookieSettingsState = {
  cookieSettings: null,
  isCookieSettingLoading: false,
  isCookieSettingsLoaded: false
};

export const cookieSettingsReducer = createReducer(cookieSettingsState, {
  [COOKIE_SETTINGS_STORE_START]: (state, action) => {
    state.isCookieSettingLoading = true;
  },
  [COOKIE_SETTINGS_STORE_DONE]: (state, action) => {
    state.cookieSettings = action.payload;
    state.isCookieSettingLoading = false;
  },
  [COOKIE_SETTINGS_FETCH]: (state, action) => {
    state.isCookieSettingLoading = true;
  },
  [COOKIE_SETTINGS_FETCH_DONE]: (state, action) => {
    state.cookieSettings = action.payload;
    state.isCookieSettingLoading = false;
    state.isCookieSettingsLoaded = true;
  }
});
