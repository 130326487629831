import React, { createContext, createRef, useState, useEffect } from 'react';
import { handleQuery } from '@zert-packages/actions/api';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { useDispatch, useSelector } from 'react-redux';
import { updateWantToSeeIntro } from '@zert-packages/actions/commonReducer';
import ActionPlanIntro from './pages/ActionPlanIntro';
import ChecklistIntro from './pages/ChecklistIntro';
import SafetyInspectionIntro from './pages/SafetyInspectionIntro';
import ProjectIntro from './pages/ProjectIntro';
import CatalogIntro from './pages/CatalogIntro';
import SearchIntro from './pages/SearchIntro';
import RiskAssessmentIntro from './pages/RiskAssessmentIntro';
import WantToSeeIntoDialog from './WantToSeeIntroDialog';

export const IntroductionContext = createContext(null);
const sideBarSettingsRef = createRef();
const sideBarLinksRef = createRef();

export function IntroductionProvider({ children }) {
  const isMobile = window.innerWidth < 1024;
  //  const wantToSeeInto = useRef({ value: false });
  const wantToSeeIntro = useSelector((state) => state.wantToSeeIntro);
  const actionPlan = ActionPlanIntro(isMobile, sideBarSettingsRef, wantToSeeIntro, sideBarLinksRef);
  const checklist = ChecklistIntro(isMobile, wantToSeeIntro);
  const safetyInspection = SafetyInspectionIntro(wantToSeeIntro);
  const project = ProjectIntro(wantToSeeIntro);
  const catalog = CatalogIntro(wantToSeeIntro);
  const search = SearchIntro(wantToSeeIntro);
  const riskAssessment = RiskAssessmentIntro(wantToSeeIntro);


  /*  const wantToSeeIntro = useSelector(state => state.wantToSeeIntro);
  const actionPlan = ActionPlanIntro(isMobile, sideBarSettingsRef, wantToSeeIntro);
  const checklist = ChecklistIntro(isMobile, wantToSeeIntro);
  const safetyInspection = SafetyInspectionIntro(wantToSeeIntro);
  const project = ProjectIntro(wantToSeeIntro);
  const catalog = CatalogIntro(wantToSeeIntro);
  const search = SearchIntro(wantToSeeIntro);
  const riskAssessment = RiskAssessmentIntro(wantToSeeIntro); */
  const [storingEverything, setStoringEverything] = useState(false);
  const [cancelEverything, setCancelEverything] = useState(false);
  const [expandEverything, setExpandEverything] = useState(false);
  const [isSearchingMode, setIsSearchingMode] = useState(false);
  const [searchTab, setSearchTab] = useState(null);
  const [sectionExpandEverything, setSectionExpandEverything] = useState(null);

  const value = {
    actionPlan,
    checklist,
    safetyInspection,
    project,
    catalog,
    search,
    riskAssessment,
    storingEverything,
    setStoringEverything,
    cancelEverything,
    setCancelEverything,
    expandEverything,
    setExpandEverything,
    isSearchingMode,
    setIsSearchingMode,
    searchTab,
    setSearchTab,
    sectionExpandEverything,
    setSectionExpandEverything,
    toggleSideBarMenu: (state, handleClose) => {
      actionPlan.toggleSideBarMenu(state, handleClose);
    },
    sideBarSettingsRef,
    sideBarLinksRef
  };
  return <IntroductionContext.Provider value={value}>{children}</IntroductionContext.Provider>;
}

export function getIntroduction(id) {
  return handleQuery(`/common/showIntro/${id}`);
}

export function useIntroduction(id, set) {
  const [isIntroducing, setIsIntroducing] = useState(false);
  const wantToSeeIntro = useSelector((state) => state.wantToSeeIntro);
  const hasLicense = useSelector((state) => state.hasLicense);
  const dispatch = useDispatch();
  const startIntro = () => {
    if (isIntroducing) return;
    setIsIntroducing(true);
  };

  async function getIntro() {
    try {
      const response = await getIntroduction(id).then((value) => value);

      if (response && wantToSeeIntro === null) {
        renderInExceptionRoot(WantToSeeIntoDialog, {
          onYes: () => {
            set(response);
            dispatch(updateWantToSeeIntro(true));
          },
          onNo: () => {
            if (response) finishIntroduction(id);
            dispatch(updateWantToSeeIntro(false));
          }
        });
      } else if (wantToSeeIntro) {
        set(response);
      } else {
        set(false);
        dispatch(updateWantToSeeIntro(false));
        if (response) finishIntroduction(id);
      }
    } catch (err) {
      throw new Error(err);
    }
  }

  useEffect(() => {
    if (isIntroducing && hasLicense === true) getIntro();
  }, [isIntroducing, hasLicense]);

  /*  useEffect(() => {
    set(wantToSeeIntro);;
  }, [wantToSeeIntro]); */

  return {
    startIntro
  };
}

export function finishIntroduction(id) {
  handleQuery(`/common/introViewed/${id}`)
    .then((res) => {})
    .catch((error) => {
      throw new error();
    });
}
