import _ from 'lodash';
import { useState, useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import useCookieSettings from './useCookieSettings';

export default function useCookie(name: string, defaultValue: any, expires: number, path?: string) {
  const { cookieSettings } = useCookieSettings();
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (cookieSettings && !cookieSettings.userExperience) {
      setValue(defaultValue);
    } else {
      const cookie = Cookies.get(name, path ? { path } : undefined);
      if (cookie !== undefined) {
        try {
          const cookieValue = JSON.parse(cookie);
          if (!_.isEqual(value, cookieValue)) {
            setValue(cookieValue);
          }
        } catch (error) {
          setValue(defaultValue);
        }
      } else if (!_.isEqual(value, defaultValue)) {
        setValue(defaultValue);
      }
    }
  }, [cookieSettings, name, path, defaultValue]);

  const updateCookie = useCallback(
    (newValue: any, updateCookieStorage = true) => {
      if (cookieSettings && cookieSettings.userExperience && updateCookieStorage) {
        const newValueString = JSON.stringify(newValue);
        const options = { expires, path };
        Cookies.set(name, newValueString, options);
      }
      setValue(newValue);
    },
    [name, expires, path, cookieSettings]
  );

  const deleteCookie = useCallback(() => {
    Cookies.remove(name, path ? { path } : undefined);
    setValue(defaultValue);
  }, [name, path, defaultValue]);

  return [value, updateCookie, deleteCookie];
}
