import React, { useEffect, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TreeItem } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { getPluginByMimeTypeIcon } from '@zert-packages/utils/getPluginByMimeType';
import { getFormatTemplate } from '@zert-packages/actions/coreReducers';
import BlockIcon from '@zert-packages/muiadopticons/BlockIcon';
import TreeItemBranch from './TreeItemBranch';
import { PanelContext } from '../../InfoElementWrapper/InfoElementWrapper';

const useStyles = makeStyles((theme) => ({
  treeItemRoot: {
    '& .MuiCollapse-root': {
      marginLeft: '0 !important'
    },
    '& .MuiTreeItem-iconContainer': {
      display: 'none'
    },
    '& .MuiTreeItem-label': {
      paddingLeft: 0
    }
  },
  treeItemContent: (props) => ({
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: props.selected ? '#ECF6FB !important' : '#FFFFFF !important'
  }),
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '15px 1fr',
    gridGap: 10
  },
  imgArrow: {
    height: 15,
    transform: 'rotate(270deg)',
    position: 'relative'
  },
  imgArrowExpend: {
    height: 15,
    display: 'flex',
    alignItems: 'center'
  },
  infoImg: {
    margin: '0 auto',
    '& svg': {
      height: 15
    },
    height: 15,
    display: 'flex'
  },
  textTitle: {}
}));

function TreeItemParentsBranch({ expand, rootNode, setExpanded, selected, structureMain, context }) {
  const cl = useStyles({ selected: +selected === 1 });
  const { versionInfo, formatTemplate } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setValues } = context || useContext(PanelContext);

  const scrollTop = () => {
    setValues((pre) => ({ ...pre, scrollTop: true }));
  };

  useEffect(() => {
    if (!formatTemplate) {
      dispatch(getFormatTemplate());
    }
  }, []);

  if (!rootNode) {
    return;
  }
  // console.log('rootNode', rootNode);
  return (
    <TreeItem
      className={`${cl.treeItemRoot} structureElementExpanded`}
      nodeId="1"
      classes={{ content: cl.treeItemContent }}
      label={
        <div className={cl.wrapper}>
          {/* <span className={expand.find((item) => +item === 1) ? cl.imgArrowExpend : cl.imgArrow}>
            <ArrowDropDownIcon fontSize="small" />
          </span> */}

          <span className={cl.infoImg}>
            {versionInfo && <BlockIcon svg={getPluginByMimeTypeIcon(versionInfo.info.mimeType, true)} />}
          </span>
          <span
            className={cl.textTitle}
            style={{ color: +selected === 1 ? '#01A1C7' : '' }}
            onClick={() => scrollTop()}
          >
            {versionInfo && versionInfo.info.name}
          </span>
        </div>
      }
    >
      {rootNode &&
        rootNode.map((el, i) => (
          <TreeItemBranch
            depth={1}
            context={context}
            key={`${el.elementInfo.versionId} ${i} ${el?.elementInfo?.changedAt}`}
            structureMain={structureMain}
            el={el}
            expand={expand}
            setExpanded={setExpanded}
            selected={selected}
          />
        ))}
    </TreeItem>
  );
}

export default TreeItemParentsBranch;
